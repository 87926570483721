export const ContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '20px',
};

export const TitleStyle = {
  fontWeight: 'bold',
  paddingBottom: '20px',
};

export const subTitleStyle = {
  fontWeight: 600,
  paddingTop: '20px',
};

export const ImageContainer = {
  marginTop: '20px',
};

export const ListConteinerStyle = {
  display: 'flex',
  padding: '10px',
};

export const lhsStyle = {
  // width: '120px',
  fontWeight: 600,
};

export const rhsStyle = {
  paddingLeft: '5px',
};

export const ButtonContainer = {
  margin: '20px',
};
