import React from 'react';
import { Image, Button } from 'react-bootstrap';
import * as S from './styles';

export const VerifiedUser = (props: any) => {
  const _renderDetails = () => {
    let _res: any[] = [];
    const _data = props?.userDetails;
    _data &&
      Array.isArray(_data) &&
      _data.length > 0 &&
      _data.map((item: any, index: number) => {
        _res.push(
          <div style={S.ListConteinerStyle}>
            <span
              style={S.lhsStyle}
              dangerouslySetInnerHTML={{ __html: item?.key }}
            />
            {` :   `}
            <span
              style={S.rhsStyle}
              dangerouslySetInnerHTML={{ __html: item?.value }}
            />
          </div>
        );
      });

    return <div>{_res}</div>;
  };

  const _renderBotton = () => {
    return (
      <>
        <Button
          style={S.ButtonContainer}
          variant='info'
          onClick={props?.onButtonClick}
        >
          Eligible Grants / ಅರ್ಹ ಅನುದಾನಗಳು
        </Button>
      </>
    );
  };

  return (
    <>
      <div style={S.ContainerStyle}>
        <Image src={props?.headerLogo} width={120} height={120} />
        <div style={S.TitleStyle}>{props?.title}</div>
        <Image
          style={S.ImageContainer}
          src={props?.userLogo}
          roundedCircle
          width={80}
          height={80}
        />
        {_renderDetails()}
        {_renderBotton()}
      </div>
    </>
  );
};
