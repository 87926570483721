import React from 'react';
import { TweetyWidget } from '@vlinder-web/tweety-widget-react';
import * as S from './styles';
import { Image, Button } from 'react-bootstrap';

export const VerifyUser = (props: any) => {
  return (
    <>
      <div style={S.ContainerStyle}>
        <Image src={props?.headerLogo} width={120} height={120} />
        <div style={S.TitleStyle}>{props?.title}</div>
        <div style={S.subTitleStyle}>{props?.subTitle}</div>
      </div>
      <div style={{ padding: '20px' }}>
        <TweetyWidget fields={props?.fields} submitButtonLabel='Submit' />
      </div>
    </>
  );
};

VerifyUser.defaultProps = {
  fields: [
    {
      type: 'text',
      compProps: {
        id: 'name',
        label: 'Name / ಹೆಸರು',
        required: true,
        placeholder: 'Enter your name',
        errorText: 'Name is required',
      },
    },
    {
      type: 'datepicker',
      compProps: {
        id: 'dob',
        label: 'DOB / ಹು.ದಿ',
        required: true,
        errorText: 'DOB is required',
      },
    },
    {
      type: 'radio',
      compProps: {
        id: 'gender',
        label: 'Gender / ಲಿಂಗ',
        required: true,
        errorText: 'gender is required',
        direction: 'horizontal',
        options: [
          {
            id: 'male',
            label: 'Male',
          },
          { id: 'female', label: 'Female' },
        ],
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'aadhar',
        label: 'Aadhar / ಆಧಾರ್',
        required: true,
        placeholder: 'Enter your Aadhar',
        errorText: 'Aadhar is required',
      },
    },
    {
      type: 'phoneNumber',
      compProps: {
        id: 'phoneNumber',
        label: 'Mobile Number / ದೂರವಾಣಿ ಸಂಖ್ಯೆ',
        required: false,
        placeholder:
          'Select your country code first and then Enter mobile Number',
        errorText: 'Mobile number is required',
      },
    },
  ],
};
